/* eslint-disable no-unused-expressions */
/* eslint no-console: ["warn", { allow: ["warn"] }] */
import mapboxgl, { AnyLayer, Expression, Layer, Sources, Style } from "mapbox-gl";
import { v4 as uuid } from "uuid";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { LineJoin } from "@iventis/domain-model/model/lineJoin";
import { LineEnd } from "@iventis/domain-model/model/lineEnd";
import GeoJSON from "geojson";
import { IconPlacement } from "@iventis/domain-model/model/iconPlacement";
import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { ZoomableValue } from "@iventis/domain-model/model/zoomableValue";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { ZoomValue } from "@iventis/domain-model/model/zoomValue";
import { IconOrientation } from "@iventis/domain-model/model/iconOrientation";
import { LineType } from "@iventis/domain-model/model/lineType";
import { BBox, Feature, featureCollection } from "@turf/helpers";
import { TextPosition } from "@iventis/domain-model/model/textPosition";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { DataField } from "@iventis/domain-model/model/dataField";
import bbox from "@turf/bbox";
import { BBox2d, Polygon } from "@turf/helpers/dist/js/lib/geojson";
import {
    feetInMile,
    feetToMeter,
    metersInKilometer,
    mileToMeter,
    squareFeetToSquareMeter,
    squaredFeetInSquaredMile,
    smallestMilesValueToShow,
    smallestKilometerValueToShow,
    squareMeterToSquareMile,
    metersSquaredInKiloMeterSquared,
} from "@iventis/utilities/src/unit-conversion/constants";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { IconAlignment } from "@iventis/domain-model/model/iconAlignment";
import { getStaticStyleValue, createStaticStyleValue, getStaticStyleValueFromMapped } from "@iventis/layer-style-helpers";
import { AnySupportedGeometry, LocalGeoJsonObject, MapObjectProperties } from "@iventis/map-types";
import { getMapObjectAreaSystemDataField, getMapObjectLengthSystemDataField } from "@iventis/datafields";
import { getDefaultStyleProperty, getStaticAndMappedValues, getTextStyle } from "../../utilities/style-helpers";
import { BasicMapLayer, LayerStorageScope, MapboxEngineData, MapModuleLayer, MapState, TileSource } from "../../types/store-schema";
import { removeLayerIdSuffixes, getCentroidLayerID } from "../engine-generic";
import {
    transparentColour,
    LineEndString,
    LineJoinString,
    AnySupportedMapboxLayer,
    boldFontStack,
    regularFontStack,
    automaticTextAnchor,
    iventisToMapboxTextStyleProperty,
    MapboxInitialPosition,
    ExportOptions,
    ApplyListItemTextFunction,
} from "./engine-mapbox-types-and-constants";
import { AggregateLayers, MapboxlayerWithSublayerType, SubLayerType } from "./sublayer-types";
import { AssetOptions, CompositionMapObject, StylePropertyToValueMap } from "../../types/internal";
import { MAPBOX_MAX_ZOOM, MAPBOX_MIN_ZOOM, localSuffix, triangleSprintName, building3dLayerIds, buildingLayerIds } from "../constants";
import { ModelGeometry } from "./3d-engine/engine-3d-types";
import { DeckglEngine } from "./3d-engine/deckgl/engine-deckgl";
import { SitemapStyle } from "../../types/sitemap-style";

export function iventisSourceToMapboxSource(iventisSource: TileSource, bounds?: BBox): mapboxgl.AnySourceData {
    const mbxSourceValue: mapboxgl.AnySourceData = {
        type: "vector",
    };

    if (iventisSource.tiles !== undefined) {
        mbxSourceValue.tiles = iventisSource.tiles;
    }

    if (iventisSource.url !== undefined) {
        mbxSourceValue.url = iventisSource.url;
    }

    if (bounds != null) {
        mbxSourceValue.bounds = bounds;
    }

    return mbxSourceValue;
}

export const iventisLayerToHighlightBaseLayer = (
    iventisLayer: MapModuleLayer,
    createModelLayer: DeckglEngine["createLayer"],
    preview: boolean,
    id = uuid(),
    filter: Layer["filter"],
    applyListItemText?: (attributeId: string, textLayerId: string, iventisLayerId: string) => ApplyListItemTextFunction
): AnySupportedMapboxLayer => {
    switch (iventisLayer.styleType) {
        case StyleType.Line:
            return iventisLineToBaseSublayer(iventisLayer, id, filter);
        case StyleType.Area:
            return iventisAreaToBaseSublayer(iventisLayer, id, filter);
        case StyleType.Point:
            return iventisPointToBaseSublayer(iventisLayer, id, filter);
        case StyleType.Icon:
            return iventisIconToBaseSublayer(iventisLayer, preview, {}, { projectDataFields: [], unitOfMeasurement: UnitOfMeasurement.Metric }, id, filter, applyListItemText);
        case StyleType.LineModel:
            return iventisLineModelToBaseSublayer(iventisLayer, id);
        case StyleType.Model:
        default:
            throw new Error("Layer type is not implemented");
    }
};

// parse an Iventis area layer to a mapbox fill layer
export function iventisAreaToAggregateLayer(
    iventisArea: MapModuleLayer,
    preview: boolean,
    lengthAndArea: {
        projectDataFields: DataField[];
        unitOfMeasurement: UnitOfMeasurement;
    },
    filter: Layer["filter"],
    applyListItemText?: (attributeId: string, textLayerId: string, iventisLayerId: string) => ApplyListItemTextFunction
): MapboxlayerWithSublayerType[] {
    const aggregatedLayers: MapboxlayerWithSublayerType[] = [];

    if (getStaticStyleValue(iventisArea.areaStyle.dimension) === AreaDimension.Three) {
        const extrusion = iventis3DAreaToBaseSublayer(iventisArea, iventisArea.id, filter);
        aggregatedLayers.push({ type: SubLayerType.EXTRUSION, id: extrusion.id, layer: extrusion });
    } else {
        // Only include fill, text, outline if we're on 2D area layers.
        if (getStaticStyleValue(iventisArea.areaStyle.fill)) {
            const base = iventisAreaToBaseSublayer(iventisArea, iventisArea.id, filter);
            aggregatedLayers.push({ type: SubLayerType.BASE, id: base.id, layer: base });
        }
        if (getStaticStyleValue(iventisArea.areaStyle.outline)) {
            const outlineLayer = createOutlineSubLayer(iventisArea, iventisArea.source, iventisArea.storageScope, iventisArea.areaStyle, iventisArea.visible, filter);
            aggregatedLayers.push({ type: SubLayerType.POLYGON_OUTLINE, id: outlineLayer.id, layer: outlineLayer });
        }
        if (getStaticStyleValue(iventisArea.areaStyle.text)) {
            const textLayer = createTextSublayer(iventisArea, iventisArea.areaStyle, preview, lengthAndArea, filter, applyListItemText);
            aggregatedLayers.push({ type: SubLayerType.POLYGON_TEXT, id: textLayer.id, layer: textLayer });
        }
    }

    // if the layer is remote
    if (!iventisArea.id.includes(`_${localSuffix}`) && iventisArea.remote) {
        // set source layer id as base layer id
        aggregatedLayers.forEach(({ layer, type }) => {
            if (type === SubLayerType.POLYGON_TEXT) {
                // eslint-disable-next-line no-param-reassign
                layer["source-layer"] = removeLayerIdSuffixes(getCentroidLayerID(iventisArea.id));
            } else {
                // eslint-disable-next-line no-param-reassign
                layer["source-layer"] = removeLayerIdSuffixes(iventisArea.id);
            }
        });
    }
    return aggregatedLayers;
}

export function iventisPointToAggregateLayer(
    iventisLayer: MapModuleLayer,
    preview: boolean,
    lengthAndArea: {
        projectDataFields: DataField[];
        unitOfMeasurement: UnitOfMeasurement;
    },
    filter: Layer["filter"],
    applyListItemText?: (attributeId: string, textLayerId: string, iventisLayerId: string) => ApplyListItemTextFunction
): MapboxlayerWithSublayerType[] {
    const aggregatedLayers: MapboxlayerWithSublayerType[] = [];
    const base: mapboxgl.CircleLayer = iventisPointToBaseSublayer(iventisLayer, iventisLayer.id, filter);

    aggregatedLayers.push({ type: SubLayerType.BASE, id: base.id, layer: base });

    if (getStaticStyleValue(iventisLayer.pointStyle.text)) {
        const textLayer = createTextSublayer(iventisLayer, iventisLayer.pointStyle, preview, lengthAndArea, filter, applyListItemText);
        aggregatedLayers.push({ type: SubLayerType.POINT_TEXT, id: textLayer.id, layer: textLayer });
    }

    return aggregatedLayers;
}

export function iventisIconToAggregateLayer(
    iventisLayer: MapModuleLayer,
    preview: boolean,
    sdfIconIds: { [key: string]: string },
    lengthAndArea: {
        projectDataFields: DataField[];
        unitOfMeasurement: UnitOfMeasurement;
    },
    filter: Layer["filter"],
    applyListItemText?: (attributeId: string, textLayerId: string, iventisLayerId: string) => ApplyListItemTextFunction
): MapboxlayerWithSublayerType[] {
    const aggregatedLayers: MapboxlayerWithSublayerType[] = [];

    const base: mapboxgl.SymbolLayer = iventisIconToBaseSublayer(iventisLayer, preview, sdfIconIds, lengthAndArea, iventisLayer.id, filter, applyListItemText);

    aggregatedLayers.push({ type: SubLayerType.BASE, id: base.id, layer: base });

    return aggregatedLayers;
}

export const iventisModelToAggregateLayer = (iventisLayer: MapModuleLayer, createLayer: DeckglEngine["createLayer"]): MapboxlayerWithSublayerType[] => {
    const aggregatedLayers: MapboxlayerWithSublayerType[] = [];

    const baseLayers = iventisModelToBaseSublayer(iventisLayer, createLayer);

    baseLayers.forEach((layer) => {
        aggregatedLayers.push({ type: SubLayerType.BASE, id: layer.id, layer });
    });

    return aggregatedLayers;
};

export function createTextSublayer<Style extends AreaStyle | PointStyle | LineStyle>(
    layer: MapModuleLayer,
    style: Style,
    preview: boolean,
    lengthAndArea: {
        projectDataFields: DataField[];
        unitOfMeasurement: UnitOfMeasurement;
    },
    filter: Layer["filter"],
    applyListItemText?: (attributeId: string, textLayerId: string, iventisLayerId: string) => ApplyListItemTextFunction
) {
    const { id, source, storageScope, visible } = layer;
    const textLayerId = uuid();

    let textContentValue = style.textContent;

    if (textContentValue == null || textContentValue.dataFieldId == null) {
        textContentValue = getDefaultStyleProperty(style.styleType, "textContent");
    }

    const textLayer: mapboxgl.SymbolLayer = {
        id: textLayerId,
        type: "symbol",
        paint: {
            "text-color": getStaticStyleValue(style.textColour ?? getDefaultStyleProperty(style.styleType, "textColour")),
            "text-halo-color": getStaticStyleValue(style.textOutlineColour ?? getDefaultStyleProperty(style.styleType, "textOutlineColour")),
            "text-halo-width": getStaticStyleValue(style.textOutlineWidth ?? getDefaultStyleProperty(style.styleType, "textOutlineWidth")),
            "text-opacity": getStaticStyleValue(style.textOpacity ?? getDefaultStyleProperty(style.styleType, "textOpacity")),
        },
        layout: {
            visibility: visible ? "visible" : "none",
            "text-allow-overlap": getStaticStyleValue(style.textOverlap ?? getDefaultStyleProperty(style.styleType, "textOverlap")),
            "text-anchor": "center",
            "text-field": textContentValueToMapboxStyleValue(textContentValue, lengthAndArea, applyListItemText?.(textContentValue.dataFieldId, textLayerId, layer.id)),
            "text-size": styleValueToMapboxStyleValue(style.textSize ?? getDefaultStyleProperty(style.styleType, "textSize")),
            "text-font": getStaticStyleValue(style.textBold ?? getDefaultStyleProperty(style.styleType, "textBold")) ? boldFontStack : regularFontStack,
        },
        source: generateTextSubLayerSource(id, source, style.styleType, storageScope),
        filter,
        metadata: {
            type: "text",
            name: layer.name,
        },
    };

    if (style.styleType === StyleType.Point) {
        textLayer.layout = {
            ...textLayer.layout,
            "text-variable-anchor": styleValueParser.textPosition(getStaticStyleValue(style.textPosition ?? getDefaultStyleProperty(style.styleType, "textPosition")), preview),
            "text-radial-offset": styleValueParser.textOffset(
                getStaticStyleValue(style.textOffset ?? getDefaultStyleProperty(style.styleType, "textOffset")),
                style.textSize ?? getDefaultStyleProperty(style.styleType, "textSize")
            ),
        };
    }

    if (style.styleType === StyleType.Line) {
        textLayer.paint = {
            ...textLayer.paint,
            "text-translate": [getStaticStyleValue(style.offset ?? getDefaultStyleProperty(style.styleType, "offset")), 0],
        };

        textLayer.layout = {
            ...textLayer.layout,
            "text-anchor": "bottom",
            "symbol-placement": "line",
        };
    }

    if (storageScope === LayerStorageScope.LocalAndTiles) {
        setRemoteSourceLayer(textLayer, id, removeLayerIdSuffixes(style.styleType === StyleType.Area ? getCentroidLayerID(id) : id));
    }

    return textLayer;
}

function generateTextSubLayerSource(id: string, source: string, styleType: StyleType, storageScope: LayerStorageScope) {
    if (styleType === StyleType.Area) {
        const isLayerLocal = id.includes(`_${localSuffix}`) || storageScope === LayerStorageScope.LocalOnly;
        return isLayerLocal ? getCentroidLayerID(id) : source;
    }

    return source;
}

export function createOutlineSubLayer<Style extends AreaStyle | LineStyle>(
    iventisLine: MapModuleLayer,
    source: string,
    storageScope: LayerStorageScope,
    style: Style,
    visible: boolean,
    filter: Layer["filter"]
) {
    const outlineLayer: mapboxgl.LineLayer = {
        id: uuid(),
        type: "line",
        layout: {
            visibility: visible ? "visible" : "none",
        },
        paint: {
            "line-color": styleValueToMapboxStyleValue(style.outlineColour ?? getDefaultStyleProperty(style.styleType, "outlineColour")),
            "line-blur": getStaticStyleValue(style.outlineBlur ?? getDefaultStyleProperty(style.styleType, "outlineBlur")),
            "line-opacity": getStaticStyleValue(style.outlineOpacity ?? getDefaultStyleProperty(style.styleType, "outlineOpacity")),
        },
        metadata: {
            name: iventisLine.name,
            type: "outline",
        },
        source,
    };

    if (style.styleType === StyleType.Area) {
        const outlineWidth = getStaticStyleValue(style.outlineWidth ?? getDefaultStyleProperty(style.styleType, "outlineWidth"));
        outlineLayer.paint = {
            ...outlineLayer.paint,
            // Ensure the outline is not overlapping the polygon
            "line-offset": calculateOffsetForPolygonOutline(outlineWidth),
            "line-width": outlineWidth,
        };
    }

    if (style.styleType === StyleType.Line) {
        const outlineWidth = calculateLineWidthForLineOutline(style.width, style.outlineWidth ?? getDefaultStyleProperty(style.styleType, "outlineWidth"));
        outlineLayer.paint = {
            ...outlineLayer.paint,
            "line-width": outlineWidth,
            "line-offset": getStaticStyleValue(style.offset ?? getDefaultStyleProperty(style.styleType, "offset")),
        };

        outlineLayer.layout = {
            ...outlineLayer.layout,
            "line-cap": "round",
            "line-join": "round",
        };
    }

    if (filter != null && filter.length !== 0) {
        outlineLayer.filter = filter;
    }

    if (storageScope === LayerStorageScope.LocalAndTiles) {
        setRemoteSourceLayer(outlineLayer, iventisLine.id);
    }

    return outlineLayer;
}

// parse an Iventis line layer to a mapbox line layer
export function iventisLineToAggregateLayer(
    iventisLine: MapModuleLayer,
    preview: boolean,
    lengthAndArea: {
        projectDataFields: DataField[];
        unitOfMeasurement: UnitOfMeasurement;
    },
    filter: Layer["filter"],
    applyListItemText?: (attributeId: string, textLayerId: string, iventisLayerId) => ApplyListItemTextFunction
): MapboxlayerWithSublayerType[] {
    const aggregatedLayers: MapboxlayerWithSublayerType[] = [];

    if (getStaticStyleValue(iventisLine.lineStyle.outline)) {
        const outlineLayer = createOutlineSubLayer(iventisLine, iventisLine.source, iventisLine.storageScope, iventisLine.lineStyle, iventisLine.visible, filter);
        aggregatedLayers.push({ type: SubLayerType.LINE_OUTLINE, id: outlineLayer.id, layer: outlineLayer });
    }

    const base: mapboxgl.LineLayer = iventisLineToBaseSublayer(iventisLine, iventisLine.id, filter);

    aggregatedLayers.push({ type: SubLayerType.BASE, id: base.id, layer: base });

    if (getStaticStyleValue(iventisLine.lineStyle.arrows)) {
        const arrowLayer = createArrowsSublayer(iventisLine, filter);
        aggregatedLayers.push({ type: SubLayerType.LINE_ICON, id: arrowLayer.id, layer: arrowLayer });
    }

    if (getStaticStyleValue(iventisLine.lineStyle.text)) {
        const textLayer = createTextSublayer(iventisLine, iventisLine.lineStyle, preview, lengthAndArea, filter, applyListItemText);
        aggregatedLayers.push({ type: SubLayerType.LINE_TEXT, id: textLayer.id, layer: textLayer });
    }

    return aggregatedLayers;
}

export function createArrowsSublayer(iventisLine: MapModuleLayer, filter: Layer["filter"]) {
    const { maxZoom, minZoom } = zoomableValueToMinMaxZoomLevels(iventisLine.lineStyle.width.staticValue);
    const arrowLayer: mapboxgl.SymbolLayer = {
        id: uuid(),
        type: "symbol",
        paint: {
            "icon-color": styleValueToMapboxStyleValue(iventisLine.lineStyle.arrowColour ?? getDefaultStyleProperty(iventisLine.lineStyle.styleType, "arrowColour")),
            "icon-opacity": getStaticStyleValue(iventisLine.lineStyle.arrowOpacity ?? getDefaultStyleProperty(iventisLine.lineStyle.styleType, "arrowOpacity")),
        },
        layout: {
            visibility: iventisLine.visible ? "visible" : "none",
            "icon-offset": [getStaticStyleValue(iventisLine.lineStyle.offset ?? getDefaultStyleProperty(iventisLine.lineStyle.styleType, "offset")), 0],
            "icon-image": triangleSprintName,
            "symbol-spacing": styleValueParser.symbolSpacing(
                getStaticStyleValue(iventisLine.lineStyle.arrowSpacing ?? getDefaultStyleProperty(iventisLine.lineStyle.styleType, "arrowSpacing"))
            ),
            "icon-rotation-alignment": "map",
            "icon-allow-overlap": true,
            "icon-rotate": 90,
            "symbol-placement": styleValueParser.iconPlacement(
                getStaticStyleValue(iventisLine.lineStyle.iconPlacement ?? getDefaultStyleProperty(iventisLine.lineStyle.styleType, "iconPlacement"))
            ),
            "icon-size": getStaticStyleValue(iventisLine.lineStyle.arrowSize ?? getDefaultStyleProperty(iventisLine.lineStyle.styleType, "arrowSize")),
            "symbol-sort-key": styleValueToMapboxStyleValue(iventisLine.lineStyle.objectOrder ?? createStaticStyleValue(0)),
        },
        filter,
        ...addMaxMinZoomLevels(maxZoom, minZoom),
        source: iventisLine.source,
        metadata: {
            name: iventisLine.name,
            type: "arrows",
        },
    };

    if (iventisLine.storageScope === LayerStorageScope.LocalAndTiles) {
        setRemoteSourceLayer(arrowLayer, iventisLine.id);
    }

    return arrowLayer;
}

export function iventisLineToBaseSublayer(iventisLine: MapModuleLayer, id = uuid(), filter): mapboxgl.LineLayer {
    const { maxZoom, minZoom } = zoomableValueToMinMaxZoomLevels(iventisLine.lineStyle.width.staticValue);
    const layer: mapboxgl.LineLayer = {
        id,
        type: "line",
        paint: {
            "line-color": styleValueToMapboxStyleValue(iventisLine.lineStyle.colour),
            "line-width": styleValueToMapboxStyleValue(iventisLine.lineStyle.width),
            "line-opacity": styleValueToMapboxStyleValue(iventisLine.lineStyle.opacity) ?? 1,
            "line-blur": styleValueToMapboxStyleValue(iventisLine.lineStyle.blur) ?? 0,
            "line-offset": styleValueToMapboxStyleValue(iventisLine.lineStyle.offset) ?? 0,
            "line-dasharray": styleValueParser.lineType(getStaticStyleValue(iventisLine.lineStyle.type ?? getDefaultStyleProperty(iventisLine.lineStyle.styleType, "type"))),
        },
        layout: {
            visibility: iventisLine.visible ? "visible" : "none",
            "line-cap": getStaticStyleValue(iventisLine.lineStyle.end) != null ? styleValueParser.end(getStaticStyleValue(iventisLine.lineStyle.end)) : "round",
            "line-join": getStaticStyleValue(iventisLine.lineStyle.end) != null ? styleValueParser.join(getStaticStyleValue(iventisLine.lineStyle.join)) : "round",
            "line-sort-key": styleValueToMapboxStyleValue(iventisLine.lineStyle.objectOrder ?? createStaticStyleValue(0)),
        },
        ...addMaxMinZoomLevels(maxZoom, minZoom),
        source: iventisLine.source,
        metadata: {
            name: iventisLine.name,
            type: "base",
        },
    };

    if (filter != null && filter.length !== 0) {
        layer.filter = filter;
    }

    if (iventisLine.storageScope === LayerStorageScope.LocalAndTiles) {
        setRemoteSourceLayer(layer, iventisLine.id);
    }

    return layer;
}

export function iventisAreaToBaseSublayer(iventisArea: MapModuleLayer, id = uuid(), filter: Layer["filter"]): mapboxgl.FillLayer | mapboxgl.FillExtrusionLayer {
    const layer: mapboxgl.FillLayer = {
        id,
        type: "fill",
        paint: {
            "fill-color": styleValueToMapboxStyleValue(iventisArea.areaStyle.colour),
            "fill-opacity": styleValueToMapboxStyleValue(iventisArea.areaStyle.opacity) ?? 1,
            "fill-outline-color": styleValueToMapboxStyleValue(iventisArea.areaStyle.outline) ? "black" : transparentColour,
        },
        layout: {
            visibility: iventisArea.visible ? "visible" : "none",
            "fill-sort-key": styleValueToMapboxStyleValue(iventisArea.areaStyle.objectOrder ?? createStaticStyleValue(0)),
        },
        source: iventisArea.source,
        metadata: {
            name: iventisArea.name,
            type: "base",
        },
    };

    if (filter != null && filter.length !== 0) {
        layer.filter = filter;
    }

    if (iventisArea.storageScope === LayerStorageScope.LocalAndTiles) {
        setRemoteSourceLayer(layer, iventisArea.id);
    }

    return layer;
}

export function iventis3DAreaToBaseSublayer(iventisArea: MapModuleLayer, id = uuid(), filter: Layer["filter"]): mapboxgl.FillExtrusionLayer {
    const layer: mapboxgl.FillExtrusionLayer = {
        id,
        type: "fill-extrusion",
        paint: {
            "fill-extrusion-color": styleValueToMapboxStyleValue(iventisArea.areaStyle.colour) ?? transparentColour,
            "fill-extrusion-opacity": getStaticStyleValue(iventisArea.areaStyle.opacity) ?? 1,
            "fill-extrusion-height": getStaticStyleValue(iventisArea.areaStyle.height) ?? 3,
        },
        layout: {
            visibility: iventisArea.visible ? "visible" : "none",
        },
        source: iventisArea.source,
        metadata: {
            name: iventisArea.name,
            type: "base",
        },
        filter,
    };

    if (iventisArea.storageScope === LayerStorageScope.LocalAndTiles) {
        setRemoteSourceLayer(layer, iventisArea.id);
    }
    return layer;
}

export function iventisPointToBaseSublayer(iventisPoint: MapModuleLayer, id = uuid(), filter: Layer["filter"]): mapboxgl.CircleLayer {
    const { maxZoom, minZoom } = zoomableValueToMinMaxZoomLevels(iventisPoint.pointStyle.radius.staticValue);
    const layer: mapboxgl.CircleLayer = {
        id,
        type: "circle",
        paint: {
            "circle-color": styleValueToMapboxStyleValue(iventisPoint.pointStyle.colour),
            "circle-opacity": styleValueToMapboxStyleValue(iventisPoint.pointStyle.opacity),
            "circle-blur": styleValueToMapboxStyleValue(iventisPoint.pointStyle.blur),
            "circle-radius": styleValueToMapboxStyleValue(iventisPoint.pointStyle.radius),
            "circle-stroke-color": styleValueToMapboxStyleValue(iventisPoint.pointStyle.outlineColour) || "#000000",
            "circle-stroke-opacity": styleValueToMapboxStyleValue(iventisPoint.pointStyle.outlineOpacity),
            "circle-stroke-width": styleValueToMapboxStyleValue(iventisPoint.pointStyle.outline) ? styleValueToMapboxStyleValue(iventisPoint.pointStyle.outlineWidth) || 1 : 0,
        },
        layout: {
            visibility: iventisPoint.visible ? "visible" : "none",
            "circle-sort-key": styleValueToMapboxStyleValue(iventisPoint.pointStyle.objectOrder ?? createStaticStyleValue(0)),
        },
        metadata: {
            name: iventisPoint.name,
            type: "base",
        },
        source: iventisPoint.source,
        ...addMaxMinZoomLevels(maxZoom, minZoom),
    };

    if (filter != null && filter.length !== 0) {
        layer.filter = filter;
    }

    if (iventisPoint.storageScope === LayerStorageScope.LocalAndTiles) {
        setRemoteSourceLayer(layer, iventisPoint.id);
    }

    return layer;
}

/**
 * We only want to add the maxZoom and MinZoom filters to our layers if they are different to
 * our mapbox max/min levels, otherwise you can scroll past the max levels making our layer
 * drawing disappear
 */
export function addMaxMinZoomLevels(maxZoom: number, minZoom: number): { maxZoom?: number; minZoom?: number } {
    const objectToReturn = {};

    if (maxZoom != null && maxZoom < MAPBOX_MAX_ZOOM) {
        // eslint-disable-next-line dot-notation
        objectToReturn["maxZoom"] = maxZoom;
    }

    if (minZoom != null && minZoom > MAPBOX_MIN_ZOOM) {
        // eslint-disable-next-line dot-notation
        objectToReturn["minZoom"] = minZoom;
    }
    return objectToReturn;
}

/**
 * Checks if the iconIamge style value contains either default icons or sdf icons
 * @param iconImage - the iconImage style value
 * @param sdfIconIds - an object of mappings between sdfIcons and disabled sdfIcon ids
 * @returns [containsDefaultIcons, containsSdfIcons]
 */
export function checkSdfDefaultIcons(iconImage: StyleValue<string>, sdfIconIds: { [key: string]: string }): [boolean, boolean] {
    let containsDefaultIcons = false;
    let containsSdfIcons = false;
    Object.values({
        ...iconImage.mappedValues,
        staticValue: iconImage.staticValue,
    }).forEach((styleValue) => {
        if (!containsSdfIcons) {
            containsSdfIcons = sdfIconIds[styleValue.staticValue] != null;
        }
        if (!containsDefaultIcons) {
            containsDefaultIcons = sdfIconIds[styleValue.staticValue] == null;
        }
    });

    return [containsDefaultIcons, containsSdfIcons];
}

/**
 * Repalces the icon asset Ids to add the disabeld sdf icon suffix if the style contains both sdf and non-sdf icons in data driven styling
 * @param iconImage - the iconImage style value
 * @param sdfIconIds - an object of mappings between sdfIcons and disabled sdfIcon ids
 * @returns the new style value with iconImage ids replaced
 */
export function replaceIconIdsForSdf(iconImage: StyleValue<string>, sdfIconIds: { [key: string]: string }): StyleValue<string> {
    const [containsDefaultIcons, containsSdfIcons] = checkSdfDefaultIcons(iconImage, sdfIconIds);

    return {
        ...iconImage,
        staticValue: {
            ...iconImage.staticValue,
            staticValue:
                containsDefaultIcons && containsSdfIcons ? sdfIconIds?.[iconImage.staticValue.staticValue] ?? iconImage.staticValue.staticValue : iconImage.staticValue.staticValue,
        },
        mappedValues: Object.keys(iconImage.mappedValues ?? {}).reduce(
            (cum, key) => ({
                ...cum,
                [key]: {
                    ...cum[key],
                    staticValue:
                        containsDefaultIcons && containsSdfIcons
                            ? sdfIconIds?.[iconImage.mappedValues[key].staticValue] ?? iconImage.mappedValues[key].staticValue
                            : iconImage.mappedValues[key].staticValue,
                },
            }),
            iconImage.mappedValues
        ),
    };
}

/**
 * Checks if the given layer contains both sdf enabled and disabled icons in data-driven styling, checks both the static value and each mapped value
 * @param iventisIcon - the icon layer to be checked
 * @returns [containsSdfIcons: boolean, containsDefaultIcons: boolean, sdfIconIds: string[]]
 * @example
 * if (layer.styleType === StyleType.Icon && layer.iconStyle.iconImage.extractionMethod === StyleValueExtractionMethod.Mapped) {
        // Check all icons to see if they're SDF enabled
        const [containsSdfIcons, containsNormalIcons, sdfIconIds] = await containsSdfDefaultIcons(layer);
 }
 */
export async function containsSdfDefaultIcons(iventisIcon: MapModuleLayer, assetOptions: AssetOptions): Promise<[boolean, boolean, string[]]> {
    return Object.values({
        ...iventisIcon.iconStyle.iconImage.mappedValues,
        staticValue: iventisIcon.iconStyle.iconImage.staticValue,
    }).reduce<Promise<[boolean, boolean, string[]]>>(async (cumP, { staticValue: iconId }) => {
        const cum = await cumP;
        if (!cum[2].includes(iconId) && (await assetOptions.isAssetSdf(iconId))) {
            return [true, cum[1], [...cum[2], iconId]];
        }
        return [cum[0], true, cum[2]];
    }, Promise.resolve([false, false, []]));
}

export function iventisIconToBaseSublayer(
    iventisIcon: MapModuleLayer,
    preview: boolean,
    sdfIconIds: { [key: string]: string },
    lengthAndArea: {
        projectDataFields: DataField[];
        unitOfMeasurement: UnitOfMeasurement;
    },
    id = uuid(),
    filter: Layer["filter"],
    applyListItemText?: (attributeId: string, textLayerId: string, iventisLayerId: string) => ApplyListItemTextFunction
): mapboxgl.SymbolLayer {
    const { maxZoom, minZoom } = zoomableValueToMinMaxZoomLevels(iventisIcon.iconStyle.size.staticValue);

    const layer: mapboxgl.SymbolLayer = {
        id,
        type: "symbol",
        paint: {
            ...(iventisIcon.iconStyle.customColour?.staticValue.staticValue && { "icon-color": styleValueToMapboxStyleValue(iventisIcon.iconStyle.colour) }),
            "icon-opacity": styleValueToMapboxStyleValue(iventisIcon.iconStyle.opacity ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "opacity")),
        },
        layout: {
            "icon-image": styleValueToMapboxStyleValue(
                replaceIconIdsForSdf(iventisIcon.iconStyle.iconImage, sdfIconIds) ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "iconImage")
            ),
            "icon-size": styleValueToMapboxStyleValue(iventisIcon.iconStyle.size ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "size")),
            "icon-rotate": styleValueToMapboxStyleValue(iventisIcon.iconStyle.rotation ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "rotation")),
            "icon-allow-overlap": getStaticStyleValue(iventisIcon.iconStyle.allowOverlap ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "allowOverlap")),
            "icon-pitch-alignment": styleValueParser.iconOrientation(
                getStaticStyleValue(iventisIcon.iconStyle.orientation ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "orientation"))
            ),
            "icon-rotation-alignment": styleValueParser.iconAlignment(
                getStaticStyleValue(iventisIcon.iconStyle.iconAlignment ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "iconAlignment"))
            ),
            visibility: iventisIcon.visible ? "visible" : "none",
            "symbol-sort-key": styleValueToMapboxStyleValue(iventisIcon.iconStyle.objectOrder ?? createStaticStyleValue(0)),
        },
        filter,
        ...addMaxMinZoomLevels(maxZoom, minZoom),
        source: iventisIcon.source,
        metadata: {
            type: "base",
            name: iventisIcon.name,
        },
    };

    if (getStaticStyleValue(iventisIcon.iconStyle.text)) {
        layer.paint = {
            ...layer.paint,
            "text-color": getStaticStyleValue(iventisIcon.iconStyle.textColour ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textColour")),
            "text-opacity": getStaticStyleValue(iventisIcon.iconStyle.textOpacity ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textOpacity")),
            "text-halo-color": getStaticStyleValue(iventisIcon.iconStyle.textOutlineColour ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textOutlineColour")),
            "text-halo-width": getStaticStyleValue(iventisIcon.iconStyle.textOutlineWidth ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textOutlineWidth")),
        };

        let textContentValue = iventisIcon.iconStyle.textContent;

        if (textContentValue == null || textContentValue.dataFieldId == null) {
            textContentValue = getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textContent");
        }

        layer.layout = {
            ...layer.layout,
            "text-field": textContentValueToMapboxStyleValue(textContentValue, lengthAndArea, applyListItemText?.(textContentValue.dataFieldId, id, iventisIcon.id)),
            "text-size": styleValueToMapboxStyleValue(iventisIcon.iconStyle.textSize ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textSize")),
            "text-allow-overlap": getStaticStyleValue(iventisIcon.iconStyle.textOverlap ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textOverlap")),
            "text-font": getStaticStyleValue(iventisIcon.iconStyle.textBold ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textBold"))
                ? boldFontStack
                : regularFontStack,
            "text-variable-anchor": styleValueParser.textPosition(
                getStaticStyleValue(iventisIcon.iconStyle.textPosition ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textPosition")),
                preview
            ),
            "text-radial-offset": styleValueParser.textOffset(
                getStaticStyleValue(iventisIcon.iconStyle.textOffset ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textOffset")),
                iventisIcon.iconStyle.textSize ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "textSize")
            ),
            "icon-text-fit": styleValueParser.iconTextFit(
                getStaticStyleValue(iventisIcon.iconStyle.iconTextFit ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "iconTextFit"))
            ),
            "icon-text-fit-padding": styleValueParser.iconTextFitMargin(
                getStaticStyleValue(iventisIcon.iconStyle.iconTextFitMargin ?? getDefaultStyleProperty(iventisIcon.iconStyle.styleType, "iconTextFitMargin"))
            ),
        };
    }

    if (iventisIcon.storageScope === LayerStorageScope.LocalAndTiles) {
        setRemoteSourceLayer(layer, iventisIcon.id);
    }

    return layer;
}

export function createLineModelSubLayer(iventisLayer: MapModuleLayer, createLayer: DeckglEngine["createLayer"]) {
    return createLayer(iventisLayer.id, iventisLayer.lineModelStyle, iventisLayer.visible, iventisLayer.name);
}

export function iventisLineModelToBaseSublayer(iventisLayer: MapModuleLayer, id = uuid()): mapboxgl.LineLayer {
    const layer: mapboxgl.LineLayer = {
        id,
        type: "line",
        paint: {
            "line-opacity": 1,
            "line-color": "black",
            "line-width": 5,
        },
        // Filter is added to not show the line layer of line-model, filter is updated when the layer is being edited
        filter: ["in", "id"],
        source: iventisLayer.source,

        metadata: {
            name: iventisLayer.name,
            type: "base",
        },
    };
    return layer;
}

export function iventisLineModelToAggregateLayer(iventisLayer: MapModuleLayer, createModelLayer: DeckglEngine["createLayer"]): MapboxlayerWithSublayerType[] {
    const aggregatedLayers: MapboxlayerWithSublayerType[] = [];
    const base = iventisLineModelToBaseSublayer(iventisLayer);
    aggregatedLayers.push({ type: SubLayerType.BASE, id: base.id, layer: base });
    const modelLayers = createLineModelSubLayer(iventisLayer, createModelLayer);
    modelLayers.forEach((modelLayer) => {
        aggregatedLayers.push({ type: SubLayerType.LINE_MODEL, id: modelLayer.id, layer: modelLayer });
    });
    return aggregatedLayers;
}

export const iventisModelToBaseSublayer = (iventisModel: MapModuleLayer, createLayer: DeckglEngine["createLayer"]): mapboxgl.CustomLayerInterface[] => {
    const layers = createLayer(iventisModel.id, iventisModel.modelStyle, iventisModel.visible, iventisModel.name);
    return layers;
};

/**
 * Sets the 'source-layer' on the provided mapbox layer if the map module layer is remote
 * @param mapboxLayer The mapbox layer object to modify the source-layer of
 * @param mapModuleLayerId The layer ID to test for remote-ness and used to produce the source-layer
 * @param overrideSourceLayer If specified, is applied to the 'source-layer' property supposing the layer is remote
 */
export function setRemoteSourceLayer(mapboxLayer: mapboxgl.Layer, mapModuleLayerId: string, overrideSourceLayer?: string) {
    if (!mapModuleLayerId.includes(`_${localSuffix}`)) {
        // eslint-disable-next-line no-param-reassign
        mapboxLayer["source-layer"] = overrideSourceLayer || removeLayerIdSuffixes(mapModuleLayerId);
    }
}

// check if style property exists if not throw error
export function mapboxStylePropertyParseCheck(mapboxStyleProperty: string, styleProperty: string) {
    if (mapboxStyleProperty == null) {
        throw new Error(`Style Property ${styleProperty} was not reconsigned`);
    }
}

export const styleValueParser = {
    join: (value: LineJoin): LineJoinString => value.toLowerCase() as LineJoinString,
    end: (value: LineEnd): LineEndString => value.toLowerCase() as LineEndString,
    iconPlacement: (value: IconPlacement): mapboxgl.SymbolLayout["symbol-placement"] => {
        if (value === IconPlacement.LineCenter) {
            return "line-center";
        }
        if (value === IconPlacement.Line) {
            return "line";
        }
        if (value === IconPlacement.Point) {
            return "point";
        }
        throw new Error(`Icon placement value ${value} not recognised`);
    },
    iconOrientation: (value: IconOrientation): mapboxgl.SymbolLayout["icon-rotation-alignment"] => {
        switch (value) {
            case IconOrientation.Flatten:
                return "map";
            case IconOrientation.Raise:
                return "viewport";
            default:
                throw new Error(`Icon orientation value of ${value} could not be parsed`);
        }
    },
    lineType: (value: LineType): mapboxgl.LinePaint["line-dasharray"] => {
        switch (value) {
            case LineType.Solid:
                return [1, 0];
            case LineType.Dashed:
                return [2, 2];
            default:
                throw new Error(`Line type value of ${value} could not be parsed`);
        }
    },
    /**
     * Anchor position is the opposite of where the text is positioned for example if the anchor value is top
     * the position of the text will be below the object
     */
    textPosition: (value: TextPosition, preview: boolean): mapboxgl.SymbolLayout["text-variable-anchor"] => {
        switch (value) {
            case TextPosition.Automatic:
                return preview ? ["center"] : automaticTextAnchor;
            case TextPosition.Centre:
                return ["center"];
            case TextPosition.Above:
                return ["bottom"];
            case TextPosition.Below:
                return ["top"];
            case TextPosition.Right:
                return ["left"];
            case TextPosition.Left:
                return ["right"];
            default:
                throw new Error(`Text position value of ${value} could not be parsed`);
        }
    },
    /**
     * Text offset for mapbox is measured in ems: text size in pixels = 1em
     * Convert offset form pixels to ems pixels: offset (px) / font size (px)
     * For zoomable values the font size is the largest font size
     */
    textOffset: (textOffsetPixels: number, textSizePixels: StyleValue<number>): mapboxgl.SymbolLayout["text-radial-offset"] => {
        let fontSize: number;
        if (textSizePixels.staticValue.extractionMethod === ZoomableValueExtractionMethod.Static) {
            fontSize = textSizePixels.staticValue.staticValue;
        } else {
            // Get the largest font size from the mapped values
            fontSize = Math.max(...Object.values(textSizePixels.staticValue.mappedZoomValues).map((zoomLevel) => zoomLevel.value));
        }
        return textOffsetPixels / fontSize;
    },
    iconTextFit: (value: boolean): mapboxgl.SymbolLayout["icon-text-fit"] => (value ? "both" : "none"),
    iconTextFitMargin: (value: number): mapboxgl.SymbolLayout["icon-text-fit-padding"] => [value, value, value, value],
    /* When symbol spacing is 0 it will crash the map so ensure it is never */
    symbolSpacing: (value: number): mapboxgl.SymbolLayout["symbol-spacing"] =>
        value === 0 ? getDefaultStyleProperty(StyleType.Line, "arrowSpacing").staticValue.staticValue : value,
    iconAlignment: (value: IconAlignment): mapboxgl.SymbolLayout["icon-rotation-alignment"] => {
        switch (value) {
            case IconAlignment.North:
                return "map";
            case IconAlignment.Screen:
                return "viewport";
            default:
                throw new Error(`Icon alignment value of ${value} could not be parsed`);
        }
    },
};

export function combineMapboxBaseStyles(mapBackgrounds: Style, siteMaps: SitemapStyle[]): Style {
    if (mapBackgrounds == null || siteMaps == null) {
        throw new Error("Map backgrounds or site maps are undefined");
    }
    // Add the source to start of the layer ids relating to it
    const siteMapLayers = siteMaps.flatMap((map) => map.style.layers?.map((layer) => ({ ...layer, id: createSitemapLayerId(layer, map.sitemapVersionLevelId) })));
    return {
        version: 8,
        name: mapBackgrounds.name,
        glyphs: mapBackgrounds.glyphs,
        sources: { ...mapBackgrounds.sources, ...getBaseStyleSource(siteMaps.map((map) => map.style.sources)) },
        layers: [...mapBackgrounds.layers, ...siteMapLayers],
        sprite: mapBackgrounds.sprite ?? "",
    };
}

export function getBaseStyleSource(sources: Sources[]): Sources {
    if (sources == null) {
        throw new Error("Source array was undefined");
    }

    let newSources: Sources = {};

    sources.forEach((source) => {
        newSources = { ...newSources, ...source };
    });

    return newSources;
}

export function mbxQueriedToObjects(mbxQueried: GeoJSON.Feature[]) {
    return mbxQueried.map((feature) => ({
        layerId: feature.properties.layerid ? feature.properties.layerid : null,
        objectId: feature.id as string,
        geometry: feature.geometry,
    }));
}

/** Offset to apply to outline sub layer style to ensure the layer is not overlapping the polygon */
export function calculateOffsetForPolygonOutline(outlineWidth: number) {
    return (outlineWidth / 2) * -1;
}

/** Outline width for the line needs to be outline width + line width */
export function calculateLineWidthForLineOutline(baseLineWidth: StyleValue<number>, outlineWidth?: StyleValue<number>): number {
    const lineWidth = getStaticStyleValue(baseLineWidth);
    const width = getStaticStyleValue<number>(outlineWidth);
    return lineWidth + width;
}

/**
 * Converts a domain StyleValue to a mapbox style literal value, or data driven value (expressed as an expression)
 */
export function styleValueToMapboxStyleValue<T>(styleValue: StyleValue<T>) {
    if (styleValue.extractionMethod === StyleValueExtractionMethod.Static) {
        return getZoomableValueExpression(styleValue.staticValue);
    }

    // If the style is data driven however only a default value is set and there is not mapped values
    if (styleValue.extractionMethod === StyleValueExtractionMethod.Mapped && Object.keys(styleValue?.mappedValues).length === 0) {
        return getStaticStyleValueFromMapped(styleValue);
    }

    if (styleValue.extractionMethod === StyleValueExtractionMethod.Mapped) {
        // Expression to get the value of the data field on a mapboxgl feature (object)
        const getDataFieldValue: Expression = ["get", styleValue.dataFieldId];

        /*
            Uses mapbox expressions.
            Operation is on the left, then the right hand side is one or more parameters applied to that operator.
            Expressions are nested.
            Effectively produces a number of conditionals with the flatMap roughly like so:

            if (getDataFieldValue === listItemId) {
                return <<The colour for that list item>>
            }

            But in the form of mapbox expressions
        */

        const caseExpression = Object.entries(styleValue.mappedValues).flatMap(([listItemId, zoomableValue]: [string, ZoomableValue<T>]) => [
            ["==", listItemId, getDataFieldValue] as Expression,
            zoomableValue.staticValue,
        ]);

        const expression: Expression = ["case", ...caseExpression, styleValue.staticValue.staticValue];

        return expression;
    }

    // Should apply the literal value of the data attribute
    if (styleValue.extractionMethod === StyleValueExtractionMethod.Literal) {
        const expression: Expression = ["get", styleValue.dataFieldId];
        return expression;
    }

    throw new Error(`No case handled for extraction method ${styleValue.extractionMethod}`);
}

/**
 * Converts a domain textContent StyleValue to a mapbox style value
 */
export function textContentValueToMapboxStyleValue<T>(
    styleValue: StyleValue<T>,
    lengthAndArea: {
        projectDataFields: DataField[];
        unitOfMeasurement: UnitOfMeasurement;
    },
    applyListItemText?: ApplyListItemTextFunction
) {
    if (styleValue.extractionMethod === StyleValueExtractionMethod.Static) {
        // We don't have static text content, so return nothing
        return "" as T;
    }
    if (styleValue.extractionMethod === StyleValueExtractionMethod.Mapped) {
        // Expression to get the value of the data field on a mapboxgl feature (object)
        const getDataFieldValue: Expression = ["get", styleValue.dataFieldId];

        // This will load the list items and then apply the expression
        applyListItemText?.((listItems) => {
            const caseExpression = listItems.flatMap(({ id, name }) =>
                // Set the name of the list item as our stop value
                [["==", id, getDataFieldValue] as Expression, name]
            );

            return ["case", ...caseExpression, ""];
        });

        // While we're fetching those list items and constructing the expression, we just return an empty string
        return "" as T;
    }

    // Should apply the literal value of the data attribute
    if (styleValue.extractionMethod === StyleValueExtractionMethod.Literal) {
        const { projectDataFields, unitOfMeasurement } = lengthAndArea;
        const areaDataField = getMapObjectAreaSystemDataField(projectDataFields);
        const lengthDataField = getMapObjectLengthSystemDataField(projectDataFields);

        if (areaDataField?.id === styleValue.dataFieldId) {
            return createAreaExpression(unitOfMeasurement, styleValue.dataFieldId);
        }

        if (lengthDataField?.id === styleValue.dataFieldId) {
            return createLengthExpression(unitOfMeasurement, styleValue.dataFieldId);
        }

        const expression: Expression = ["get", styleValue.dataFieldId];
        return expression;
    }

    throw new Error(`No case handled for extraction method ${styleValue.extractionMethod} on textContent`);
}

export function localGeoJsonToFeatures<GeoJsonType extends AnySupportedGeometry>(geoJsonObjects: LocalGeoJsonObject[]) {
    return (geoJsonObjects ?? []).map(({ feature }) => ({ type: "Feature", ...feature } as Feature<GeoJsonType, MapObjectProperties>));
}

export function localGeoJsonToFeatureCollection<GeoJsonType extends AnySupportedGeometry>(geoJsonObjects: LocalGeoJsonObject[]) {
    return featureCollection<GeoJsonType, MapObjectProperties>(localGeoJsonToFeatures<GeoJsonType>(geoJsonObjects));
}

/** By default we use Continuous for Mapbox */
export function getZoomableValueExpression<T>(zoomableValue: ZoomableValue<T>): Expression | T {
    // NOTE: step & interpolate expressions with a zoom input value cannot be used unless the expression exists in the top-level
    // In other words, currently zoom expressions only work with static style values

    const generateExpression = (zoomLevel: number, value: ZoomValue<T>) => (value.hidden ? [zoomLevel, 0] : [zoomLevel, value.value]);

    switch (zoomableValue.extractionMethod) {
        case ZoomableValueExtractionMethod.Static: {
            return zoomableValue.staticValue;
        }
        case ZoomableValueExtractionMethod.Continuous: {
            // Bug 17224 - Map loads as white when a "Line width" has an incorrect value
            if (zoomableValue.mappedZoomValues == null || Object.keys(zoomableValue.mappedZoomValues).length === 0) {
                return zoomableValue.staticValue;
            }

            const expressions = Object.entries(zoomableValue.mappedZoomValues).flatMap<unknown[], unknown>(([zoomLevel, fundementalStyleValue]) =>
                generateExpression(Number.parseFloat(zoomLevel), fundementalStyleValue)
            );
            return ["interpolate", ["linear"], ["zoom"], ...expressions] as Expression;
        }
        case ZoomableValueExtractionMethod.Closest: {
            // Bug 17224 - Map loads as white when a "Line width" has an incorrect value
            if (zoomableValue.mappedZoomValues == null || Object.keys(zoomableValue.mappedZoomValues).length === 0) {
                return zoomableValue.staticValue;
            }

            const expressions = Object.entries(zoomableValue.mappedZoomValues).flatMap<unknown[], unknown>(([zoomLevel, fundementalStyleValue]) =>
                generateExpression(Number.parseFloat(zoomLevel), fundementalStyleValue)
            );
            return ["step", ["zoom"], zoomableValue.staticValue, ...expressions] as Expression;
        }
        default: {
            return zoomableValue.staticValue;
        }
    }
}

export function zoomableValueToMinMaxZoomLevels<T>(zoomableValue: ZoomableValue<T>): { minZoom: number; maxZoom: number } {
    if (zoomableValue.extractionMethod !== ZoomableValueExtractionMethod.Static) {
        // Default our values to pos and neg infinity
        const defaultZoomLevels = { maxZoom: Number.POSITIVE_INFINITY, minZoom: Number.NEGATIVE_INFINITY };

        // Sort our array by zoom levels
        const sorted = Object.entries(zoomableValue.mappedZoomValues).map(([zoomLevel, zoomValue]) => ({ zoomLevel: Number.parseFloat(zoomLevel), hidden: zoomValue.hidden }));
        sorted.sort(({ zoomLevel: zoomLevelA }, { zoomLevel: zoomLevelB }) => zoomLevelA - zoomLevelB);

        // Sorts if we are trying to find our maximum zoom level
        let min = true;
        sorted.forEach(({ zoomLevel, hidden }) => {
            // If we are trying to find our maximum zoom level and have come across a hidden zoom level
            if (!min && hidden) {
                // make our maximum the smallest zoomlevel between our max and our zoom level
                // we do this because if we consider:
                // 16 - hidden
                // 11 - hidden
                // 6  - not hidden
                // 3  - hidden
                // we want our max to be 11
                defaultZoomLevels.maxZoom = Math.min(zoomLevel, defaultZoomLevels.maxZoom);
            } else if (min && hidden) {
                // make our minimum the largest zoomlevel between our min and our zoom level
                // we do this because if we consider:
                // 16 - hidden
                // 11 - hidden
                // 6  - not hidden
                // 3  - hidden
                // we want our min to be 3
                defaultZoomLevels.minZoom = Math.max(zoomLevel, defaultZoomLevels.minZoom);
            }
            // Since this array is ordered, if we come across one that is not hidden we can swap what we are looking for
            if (!hidden) {
                min = false;
            }
        });
        return {
            maxZoom: defaultZoomLevels.maxZoom === Number.POSITIVE_INFINITY ? 24 : defaultZoomLevels.maxZoom,
            minZoom: defaultZoomLevels.minZoom === Number.NEGATIVE_INFINITY ? 0 : defaultZoomLevels.minZoom,
        };
    }
    // Default our max and min to the limits set by mapbox
    return { maxZoom: 24, minZoom: 0 };
}

export function setMapboxTextPropertiesOnIcon(
    baseLayerId: string,
    layer: BasicMapLayer,
    styleChanges: StylePropertyToValueMap<IconStyle>[],
    preview: boolean,
    map: mapboxgl.Map,
    lengthAndArea: {
        projectDataFields: DataField[];
        unitOfMeasurement: UnitOfMeasurement;
    },
    applyListItemText?: (attributeId: string, textLayerId: string, iventisLayerId: string) => ApplyListItemTextFunction
) {
    const textStyle = getTextStyle(layer);

    Object.keys(textStyle).forEach((textStyleProperty) => {
        // Get the most recent value
        const value = styleChanges.find((change) => change.styleProperty === textStyleProperty)?.value ?? textStyle[textStyleProperty];
        const mapboxProperty = iventisToMapboxTextStyleProperty[textStyleProperty];
        switch (textStyleProperty) {
            case "textColour":
            case "textOutlineColour":
            case "textOutlineWidth":
            case "textOpacity":
                map.setPaintProperty(baseLayerId, mapboxProperty, styleValueToMapboxStyleValue(value));
                break;
            case "textOverlap":
                map.setLayoutProperty(baseLayerId, mapboxProperty, styleValueToMapboxStyleValue(value));
                break;
            case "text": {
                const textContentValue = (styleChanges.find((change) => change.styleProperty === "textContent")?.value as StyleValue<string>) ?? textStyle.textContent;
                map.setLayoutProperty(
                    baseLayerId,
                    mapboxProperty,
                    getStaticStyleValue(value)
                        ? textContentValueToMapboxStyleValue(textContentValue, lengthAndArea, applyListItemText?.(value?.dataFieldId, baseLayerId, layer.id))
                        : ""
                );
                break;
            }
            case "textSize": {
                map.setLayoutProperty(baseLayerId, mapboxProperty, styleValueToMapboxStyleValue(value));
                // Text size is correlated to text offset therefore offset to be changed when size changes
                const offsetValue = (styleChanges.find((change) => change.styleProperty === "textOffset")?.value as StyleValue<number>) ?? textStyle.textOffset;
                map.setLayoutProperty(baseLayerId, "text-radial-offset", styleValueParser.textOffset(getStaticStyleValue<number>(offsetValue), value));
                break;
            }
            case "textBold":
                map.setLayoutProperty(baseLayerId, mapboxProperty, styleValueToMapboxStyleValue(value) ? boldFontStack : regularFontStack);
                break;
            case "textContent":
                {
                    const textValue = (styleChanges.find((change) => change.styleProperty === "text")?.value as StyleValue<boolean>) ?? textStyle.text;
                    map.setLayoutProperty(
                        baseLayerId,
                        mapboxProperty,
                        getStaticStyleValue(textValue)
                            ? textContentValueToMapboxStyleValue(value, lengthAndArea, applyListItemText?.(value?.dataFieldId, baseLayerId, layer.id))
                            : ""
                    );
                }
                break;
            case "textPosition":
                map.setLayoutProperty(baseLayerId, mapboxProperty, styleValueParser.textPosition(getStaticStyleValue(value), preview));
                break;
            case "textOffset": {
                const textSizeValue = (styleChanges.find((change) => change.styleProperty === "textSize")?.value as StyleValue<number>) ?? textStyle.textSize;
                map.setLayoutProperty(baseLayerId, mapboxProperty, styleValueParser.textOffset(getStaticStyleValue(value), textSizeValue));
                break;
            }
            // Change of style type is handled in the calling function
            // Other style properties are not implemented
            case "styleType":
            case "textItalic":
            case "textUnderlined":
            case "objectOrder":
                break;
            default:
                throw new Error(`Text style property ${textStyleProperty} was not handled`);
        }
    });
}

export function createEphemeralObjectsFor3DLayers(objects: CompositionMapObject[], isModelLayer: (layerId: string) => boolean) {
    const objectsFor3DLayers = objects.filter((object) => isModelLayer(object.layerId));
    const layerWithObjects: { [layerId: string]: Feature<ModelGeometry, MapObjectProperties>[] } = {};
    objectsFor3DLayers.forEach((object) => {
        const layer = layerWithObjects[object.layerId];
        if (layer) {
            layer.push(object.geojson as Feature<ModelGeometry, MapObjectProperties>);
        } else {
            layerWithObjects[object.layerId] = [object.geojson as Feature<ModelGeometry, MapObjectProperties>];
        }
    });
    return layerWithObjects;
}

export function getInitialMapPosition(state: MapState, useMapObjectBounds: boolean, exportOptions?: ExportOptions): Partial<MapboxInitialPosition> {
    if (exportOptions?.bounds) {
        return { bounds: [...exportOptions.bounds[0], ...exportOptions.bounds[1]] as [number, number, number, number] };
    }

    if (useMapObjectBounds && state.tileSources.value?.objects.bounds != null) {
        return { bounds: bbox(state.tileSources.value.objects.bounds) as BBox2d, fitBoundsOptions: { padding: 20, maxZoom: 16 } };
    }

    return {
        center: [state.position.value.lng, state.position.value.lat],
        zoom: state.position.value.zoom,
        bearing: state.position.value.bearing,
        pitch: state.position.value.pitch,
    };
}

/** Gets all the icon ids in an array of layers */
export function getAllLayerIconsIds(layers: MapModuleLayer[]): string[] {
    const iconIds: string[] = [];
    layers.forEach((layer) => {
        if (layer.styleType === StyleType.Icon) {
            const ids = getStaticAndMappedValues(layer.iconStyle.iconImage);
            iconIds.push(...ids);
        }
    });
    // Remove all duplicates
    return [...new Set(iconIds)];
}

/** Returns a single aggregate layer where inputted layer id is an id of one of the sub layers */
export function getAggregateLayerBySubLayerId(aggregateLayers: AggregateLayers, layerId: string) {
    if (aggregateLayers === undefined) {
        return [];
    }
    return Object.values(aggregateLayers).find((layers) => layers.find((layer) => layer.id === layerId));
}

/** Sorts an array of sub layers, ensuring SubLayerType.BASE is index 0
 *
 * Important for ordering which requires SubLayerType.BASE to be added first
 */
export function sortSubLayers(subLayers?: MapboxlayerWithSublayerType[]) {
    if (subLayers == null) {
        return [];
    }
    return subLayers.sort((a, b) => getSubLayerOrderValue(a.type) - getSubLayerOrderValue(b.type));
}

function getSubLayerOrderValue(subLayerType: SubLayerType) {
    if (subLayerType === SubLayerType.BASE) {
        return 0;
    }
    return 1;
}

/** Checks if a sub layer of an aggregate layer is a local layer */
export function isAggregateSubLayerLocal(layerId: string, aggregateLayers: AggregateLayers) {
    const relatedLayers = getAggregateLayerBySubLayerId(aggregateLayers, layerId);
    if (relatedLayers == null) {
        return false;
    }
    const baseLayer = relatedLayers.find((layer) => layer.type === SubLayerType.BASE || layer.type === SubLayerType.EXTRUSION);
    return baseLayer ? baseLayer.id.includes(localSuffix) : false;
}

/** Returns number of remote layers in given input */
export function amountOfRemoteLayers(layers: MapModuleLayer[]): number {
    if (layers == null) {
        return 0;
    }

    const remoteOnlyLayers = layers.filter((layer) => layer.remote);
    return remoteOnlyLayers.length;
}

export function getSourceBounds(mapObjectBounds: Polygon | string, emptySource: boolean): [number, number, number, number] {
    if (emptySource) {
        return [0, 0, 0, 0];
    }
    if (mapObjectBounds != null && typeof mapObjectBounds !== "string") {
        return bbox(mapObjectBounds) as [number, number, number, number];
    }
    return null;
}

/**
 *  Creates a mapbox expression to show the MapObjectArea DataField
 *
 *  Will convert the value shown to imperial or metric
 */
export function createAreaExpression(unitOfMeasurement: UnitOfMeasurement, dataFieldId: string) {
    const expression: Expression =
        unitOfMeasurement === UnitOfMeasurement.Imperial
            ? [
                  "case",
                  ["<", ["*", ["get", dataFieldId], squareFeetToSquareMeter], squaredFeetInSquaredMile * smallestMilesValueToShow],
                  ["concat", mapboxExpression2dp(["*", ["get", dataFieldId], squareFeetToSquareMeter]), "ft\u00B2"],
                  ["concat", mapboxExpression2dp(["/", ["get", dataFieldId], squareMeterToSquareMile]), "mi\u00B2"],
              ]
            : [
                  "case",
                  ["<", ["get", dataFieldId], metersSquaredInKiloMeterSquared * smallestKilometerValueToShow],
                  ["concat", ["get", dataFieldId], "m\u00B2"],
                  ["concat", mapboxExpression2dp(["/", ["get", dataFieldId], metersSquaredInKiloMeterSquared]), "km\u00B2"],
              ];
    return expression;
}

/**
 * Creates a mapbox expression to show the MapObjectLength DataField
 *
 *  Will convert the value shown to imperial or metric
 */
export function createLengthExpression(unitOfMeasurement: UnitOfMeasurement, dataFieldId: string) {
    const expression: Expression =
        unitOfMeasurement === UnitOfMeasurement.Imperial
            ? [
                  "case",
                  ["<", ["*", ["get", dataFieldId], feetToMeter], feetInMile],
                  ["concat", mapboxExpression2dp(["*", ["get", dataFieldId], feetToMeter]), "ft"],
                  ["concat", mapboxExpression2dp(["/", ["get", dataFieldId], mileToMeter]), "mi"],
              ]
            : [
                  "case",
                  ["<", ["get", dataFieldId], metersInKilometer],
                  ["concat", ["get", dataFieldId], "m"],
                  ["concat", mapboxExpression2dp(["/", ["get", dataFieldId], metersInKilometer]), "km"],
              ];
    return expression;
}

/** Rounds a number to two decimal places using mapbox expression */
function mapboxExpression2dp(expressionToRound: Expression): Expression {
    return ["/", ["round", ["*", expressionToRound, 100]], 100];
}

export function getSiteMapLayerIds(siteMaps: SitemapStyle[]) {
    return siteMaps.reduce((cadLayerIds, cadStyle) => {
        const layerIds = cadStyle.style.layers.map((layer) => createSitemapLayerId(layer, cadStyle.sitemapVersionLevelId));
        return [...cadLayerIds, ...layerIds];
    }, [] as string[]);
}

export const createSitemapLayerId = (layer: AnyLayer, siteMapVersionId: string) => (layer.type === "custom" ? layer.id : `${layer.source}-${layer.id}-${siteMapVersionId}`);

/** Using the map state, apply the necessary changes (immutably) to the background style and return it */
export const applyConfigurationToBackground = (backgroundStyle: mapboxgl.Style, state: MapState<MapboxEngineData>) => {
    let updatedBackgroundStyle = backgroundStyle;
    if (!state.buildings3D?.value) {
        updatedBackgroundStyle = JSON.parse(JSON.stringify(updatedBackgroundStyle));
        updatedBackgroundStyle.layers = updatedBackgroundStyle.layers.reduce((acc, layer: Layer) => {
            if (building3dLayerIds.includes(layer.id)) {
                /* eslint-disable no-param-reassign */
                layer.layout = { ...layer.layout, visibility: "none" };
            }
            if (buildingLayerIds.includes(layer.id)) {
                delete layer.maxzoom;
                /* eslint-enable no-param-reassign */
            }
            return [...acc, layer];
        }, []);
    }
    return updatedBackgroundStyle;
};
