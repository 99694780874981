/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-case-declarations */
import {
    iventisAreaToAggregateLayer,
    iventisIconToAggregateLayer,
    iventisLineToAggregateLayer,
    iventisPointToAggregateLayer,
} from "@iventis/map-engine/src/bridge/mapbox/engine-mapbox-helpers";
import { LayerStorageScope, MapModuleLayer } from "@iventis/map-engine";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { LineModelMapImportLayer, MapImportLayer, ModelMapImportLayer } from "../types/export-types";
import { IventisLayer } from "../types/layer-types";

/** Given an export layer get the mapbox layer(s) to represent it */
export function getMapboxLayer(exportLayer: MapImportLayer) {
    const baseLayer = createIventisBaseLayer(exportLayer);

    if (baseLayer == null) {
        throw new Error("Can't create base layer");
    }

    switch (exportLayer.styleType) {
        case StyleType.Area:
            const areaLayer = createIventisAreaLayer(exportLayer, baseLayer);
            return iventisAreaToAggregateLayer(areaLayer as MapModuleLayer, false, { projectDataFields: [], unitOfMeasurement: UnitOfMeasurement.Metric }, ["==", "level", 0]);
        case StyleType.Line:
            const lineLayer = createIventisLineLayer(exportLayer, baseLayer);
            return iventisLineToAggregateLayer(lineLayer as MapModuleLayer, false, { projectDataFields: [], unitOfMeasurement: UnitOfMeasurement.Metric }, ["==", "level", 0]);
        case StyleType.Point:
            const pointLayer = createIventisPointLayer(exportLayer, baseLayer);
            return iventisPointToAggregateLayer(pointLayer as MapModuleLayer, false, { projectDataFields: [], unitOfMeasurement: UnitOfMeasurement.Metric }, ["==", "level", 0]);
        case StyleType.Icon:
            const iconLayer = createIventisIconLayer(exportLayer, baseLayer);
            return iventisIconToAggregateLayer(iconLayer as MapModuleLayer, false, {}, { projectDataFields: [], unitOfMeasurement: UnitOfMeasurement.Metric }, ["==", "level", 0]);
        default:
            throw new Error("Unknown style type");
    }
}

/** Given an export layer return an iventis layer, deckgl uses Iventis layer not a mapbox layer */
export function createModelLayer(exportLayer: MapImportLayer): IventisLayer {
    switch (exportLayer.styleType) {
        case StyleType.Model:
            return createIventisModelLayer(exportLayer);
        case StyleType.LineModel:
            return createIventisLineModelLayer(exportLayer);
        default:
            throw new Error("Unknown style type");
    }
}

function createIventisAreaLayer(exportLayer: MapImportLayer, baseLayer: IventisLayer): IventisLayer {
    return {
        ...baseLayer,
        areaStyle: exportLayer.style as AreaStyle,
    };
}

function createIventisLineLayer(exportLayer: MapImportLayer, baseLayer: IventisLayer): IventisLayer {
    return {
        ...baseLayer,
        lineStyle: exportLayer.style as LineStyle,
    };
}

function createIventisPointLayer(exportLayer: MapImportLayer, baseLayer: IventisLayer): IventisLayer {
    return {
        ...baseLayer,
        pointStyle: exportLayer.style as PointStyle,
    };
}

function createIventisIconLayer(exportLayer: MapImportLayer, baseLayer: IventisLayer): IventisLayer {
    return {
        ...baseLayer,
        iconStyle: exportLayer.style as IconStyle,
    };
}

function createIventisModelLayer(exportLayer: ModelMapImportLayer): IventisLayer {
    return {
        ...createIventisBaseLayer(exportLayer),
        modelStyle: exportLayer.style,
    };
}

function createIventisLineModelLayer(exportLayer: LineModelMapImportLayer): IventisLayer {
    return {
        ...createIventisBaseLayer(exportLayer),
        lineModelStyle: exportLayer.style,
    };
}

/** Creates a base iventis layer from an export layer */
function createIventisBaseLayer(exportLayer: MapImportLayer): IventisLayer {
    return {
        source: exportLayer.id,
        stamp: "",
        selected: false,
        storageScope: LayerStorageScope.LocalOnly,
        id: exportLayer.id,
        name: exportLayer.name,
        visible: exportLayer.visible,
        styleType: exportLayer.styleType,
        areaStyle: undefined,
        lineStyle: undefined,
        pointStyle: undefined,
        iconStyle: undefined,
        modelStyle: undefined,
        lineModelStyle: undefined,
        dataFields: [],
        locked: false,
    };
}
