import "mapbox-gl/dist/mapbox-gl.css";
import "@iventis/styles/src/fonts/index.css";
import React, { useRef, useState } from "react";
import { StyleType } from "@iventis/domain-model/model/styleType";
import center from "@turf/center";
import { getStaticAndMappedValues } from "@iventis/map-engine/src/utilities/style-helpers";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { DeckglEngine } from "@iventis/map-engine/src/bridge/mapbox/3d-engine/deckgl/engine-deckgl";
import { Feature, Geometry } from "geojson";
import { Model } from "@iventis/domain-model/model/model";
import { MapModuleLayer, ModelData } from "@iventis/map-engine";
import { MapObjectProperties as MapEngineMapObjectProperties } from "@iventis/map-types";
import { ModelGeometry } from "@iventis/map-engine/src/bridge/mapbox/3d-engine/engine-3d-types";
import { styled, useWindowSize } from "@iventis/styles";
import {
    ImportIventisMap,
    LineModelMapImportLayer,
    ModelMapImportLayer,
    MapObjectProperties,
    AreaMapImportLayer,
    IconMapImportLayer,
    LineMapImportLayer,
    PointMapImportLayer,
} from "../types/export-types";
import { createModelLayer, getMapboxLayer } from "../helpers/layer-helpers";
import { Mapbox } from "../mapbox/mapbox";
import { layerMapObjectsToFeature } from "../helpers/geojson-helpers";
import { MapboxSupportedLayers } from "../types/mapbox-types";
import { getMapParams } from "../helpers/url-helpers";
import { doNothingAssetHelpers, getBackgroundJson, getIconAssetUrl, getMapJson, getModelAssetUrl, getModelConfigJson } from "../helpers/asset-helpers";
import { PublicMapSearchComponent } from "./search";
import { KeyComponent } from "./key-image";
import { ToggleKeyComponent } from "./toggle-key";

export const PublicMapComponent: React.FunctionComponent = () => {
    const [mapObjectFeaturesToSearch, setMapObjectFeaturesToSearch] = useState<Feature[]>([]);
    const mapboxInstanceRef = useRef<Mapbox | null>(null);
    const keyImageRef = useRef<string | null>(null);

    const { screenWidth } = useWindowSize();

    // If the device is not mobile, show key by default
    const isMobileDevice = screenWidth < 768;
    const [showKey, setShowKey] = useState(!isMobileDevice);

    const handleFeatureSelected = (feature: Feature) => {
        if (mapboxInstanceRef.current != null) {
            mapboxInstanceRef.current.goToFeature(feature);
        }
    };

    const handleMapContainerRendered = async (element: HTMLDivElement) => {
        // If mapbox has already been created, do nothing
        if (mapboxInstanceRef.current != null) {
            return;
        }

        const mapboxInstance = new Mapbox();
        mapboxInstanceRef.current = mapboxInstance;

        // Get variables from the URL
        const { bounds, exportMapId, backgroundId, modelsConfigId, layerIdsToSearch, keyImage } = getMapParams();
        keyImageRef.current = keyImage;

        // Fetch the map data
        const exportMapResponse = await fetch(getMapJson(exportMapId));
        const exportMap = (await exportMapResponse.json()) as ImportIventisMap;

        // Fetch background json
        const backgroundUrl = await fetch(getBackgroundJson(backgroundId));
        const backgroundJson = await backgroundUrl.json();

        // Fetch model config
        const modelConfigResponse = await fetch(getModelConfigJson(modelsConfigId));
        const modelConfig = (await modelConfigResponse.json()) as Model[];

        // Create mapbox map
        await mapboxInstance.createMap(element, bounds, backgroundJson);

        mapboxInstance.addInternalImages();

        const alreadyLoadedIcons: string[] = [];

        const modelsData: ModelData[] = loadModels(modelConfig);
        const modelLayersWithGeojson: { layer: LineModelMapImportLayer | ModelMapImportLayer; geojson: Feature[] }[] = [];

        const areaLayers: AreaMapImportLayer[] = [];
        const lineLayers: LineMapImportLayer[] = [];
        const iconLayers: IconMapImportLayer[] = [];
        const pointLayers: PointMapImportLayer[] = [];
        const modelLayers: ModelMapImportLayer[] = [];
        const lineModelLayers: LineModelMapImportLayer[] = [];

        // Break layers up into their respective types so we can order them correctly
        exportMap.layers.forEach((exportedLayer) => {
            switch (exportedLayer.styleType) {
                case StyleType.Area:
                    areaLayers.push(exportedLayer);
                    break;
                case StyleType.Line:
                    lineLayers.push(exportedLayer);
                    break;
                case StyleType.Icon:
                    iconLayers.push(exportedLayer);
                    break;
                case StyleType.Point:
                    pointLayers.push(exportedLayer);
                    break;
                case StyleType.Model:
                    modelLayers.push(exportedLayer);
                    break;
                case StyleType.LineModel:
                    lineModelLayers.push(exportedLayer);
                    break;
                default:
                    throw new Error(`Unknown style type`);
            }
        });

        const featuresToSearch: Feature[] = [];

        // Load map layers apart from models
        const loadMapLayers = (exportedLayer: AreaMapImportLayer | LineMapImportLayer | IconMapImportLayer | PointMapImportLayer) => {
            const mapObjectNameDataField = exportedLayer.dataFields.find((df) => df.systemDataFieldName === "MapObjectName");

            if (mapObjectNameDataField == null) {
                throw new Error("No data field found for map object name");
            }

            const features = layerMapObjectsToFeature(exportedLayer, mapObjectNameDataField.id);

            if (layerIdsToSearch.includes(exportedLayer.id)) {
                featuresToSearch.push(...features);
            }

            // If it is an icon load the icon image
            if (exportedLayer.styleType === StyleType.Icon) {
                loadIcons(exportedLayer.style, alreadyLoadedIcons, mapboxInstance);
            }

            const layers = getMapboxLayer(exportedLayer);
            // Add the source of geojson
            mapboxInstance.addSource(exportedLayer.id, features);
            layers.forEach((layer) => {
                const mapboxLayer = { ...layer.layer } as MapboxSupportedLayers;
                // Remove the filter not needed
                delete mapboxLayer.filter;
                // If an area needs a centroid source, create it
                if (typeof mapboxLayer.source === "string" && mapboxLayer.source.includes("centroid")) {
                    createCentroidSource(features, mapboxInstance, exportedLayer.id);
                }
                mapboxInstance.addLayer(mapboxLayer);
            });
        };

        // Load the model layers
        const loadModelLayers = (exportedLayer: ModelMapImportLayer | LineModelMapImportLayer) => {
            const mapObjectNameDataField = exportedLayer.dataFields.find((df) => df.systemDataFieldName === "MapObjectName");

            if (mapObjectNameDataField == null) {
                throw new Error("No data field found for map object name");
            }

            const geojson = layerMapObjectsToFeature(exportedLayer, mapObjectNameDataField.id);
            modelLayersWithGeojson.push({ layer: exportedLayer, geojson });
        };

        // Load each layer type one by one
        areaLayers.forEach(loadMapLayers);
        lineLayers.forEach(loadMapLayers);

        // Load model layers
        modelLayers.forEach(loadModelLayers);
        lineModelLayers.forEach(loadModelLayers);

        // Create a deckgl instance
        const deckglInstance = new DeckglEngine(mapboxInstance.getMap(), doNothingAssetHelpers, 0, modelsData, false);

        // For each 3d layer add a layer(s) and then add map objects
        modelLayersWithGeojson.forEach(({ layer, geojson }) => {
            const iventisLayer = createModelLayer(layer);
            const deckglLayers = deckglInstance.createLayer(layer.id, layer.style, true, layer.name);
            deckglLayers.forEach((deckglLayer) => {
                mapboxInstance.addLayer(deckglLayer);
            });
            deckglInstance.updateMapObjects(iventisLayer as MapModuleLayer, {
                type: "FeatureCollection",
                features: geojson as Feature<ModelGeometry, MapEngineMapObjectProperties>[],
            });
        });

        // Load icons and points into the map last so they are on top
        iconLayers.forEach(loadMapLayers);
        pointLayers.forEach(loadMapLayers);

        // Add the map objects to the search component
        setMapObjectFeaturesToSearch(featuresToSearch);
    };

    return (
        <Container>
            {/** If the key image is not loaded, do not render the key or the button to toggle it */}
            {keyImageRef.current != null && (
                <>
                    <KeyComponent showKey={showKey} keyImage={keyImageRef.current} />
                    <ToggleKeyComponent showKey={showKey} setShowKey={setShowKey} />
                </>
            )}
            <MapContainer>
                {mapObjectFeaturesToSearch.length > 0 && <PublicMapSearchComponent features={mapObjectFeaturesToSearch} featureClicked={handleFeatureSelected} />}
                <MapElement ref={handleMapContainerRendered} />
            </MapContainer>
        </Container>
    );
};

/** Loads an icon into mapbox */
function loadIcons(iconStyle: IconStyle, alreadyLoadedIcons: string[], mapboxInstance: Mapbox) {
    const iconIds = getStaticAndMappedValues(iconStyle.iconImage);
    iconIds.forEach((iconId) => {
        if (!alreadyLoadedIcons.includes(iconId)) {
            const iconUrl = getIconAssetUrl(iconId);
            const customColour = iconStyle.customColour.staticValue.staticValue;
            mapboxInstance.loadImage(iconUrl, iconId, customColour);
        }
        alreadyLoadedIcons.push(iconId);
    });
}

/** Loads a model */
function loadModels(models: Model[]): ModelData[] {
    const modelsData: ModelData[] = [];
    models.forEach((model) => {
        const request = async () => {
            const modelUrl = getModelAssetUrl(model.lods[0].files[0].assetId);
            const response = await fetch(modelUrl);
            const modelGlb = await response.arrayBuffer();
            return modelGlb;
        };

        modelsData.push({
            ...model,
            modelRequest: request(),
        });
    });

    return modelsData;
}

/** Creates centroid source for areas with text */
function createCentroidSource(geojson: Feature<Geometry, MapObjectProperties>[], mapboxInstance: Mapbox, layerId: string) {
    const features: Feature[] = [];
    geojson.forEach((feature) => {
        // Check for type safety
        if (feature.geometry.type === "Polygon") {
            const centerPoint = center(feature.geometry);
            features.push({ ...centerPoint, properties: feature.properties });
        }
    });
    mapboxInstance.addSource(`${layerId}_centroid`, features);
}

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const MapContainer = styled.div`
    position: relative;
    flex-shrink: 1;
    flex-grow: 1;
`;

const MapElement = styled.div`
    width: 100%;
    height: 100%;
`;
